<template>
  <div
    :class="g_darkmode ? '' : 'grey lighten-5'"
    class="pa-4"
    style="height: 92.8vh"
  >
    <v-card class="rounded-lg px-3 py-5" flat>
      <v-row no-gutters justify="start">
        <v-col
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">{{ $t("app.name") }}</div>
          <div class="font-weight-bold">{{ g_user.fullname }}</div>
        </v-col>
        <v-col
          class="divider px-3"
          :class="$vuetify.breakpoint.xsOnly && 'mb-3'"
        >
          <div class="font-weight-light">No. Induk</div>
          <div class="font-weight-bold">{{ g_user.student.nis }}</div>
        </v-col>
        <!-- <v-col class="divider px-3">
          <div class="font-weight-light">{{ $t("app.class") }}</div>
          <div class="font-weight-bold">{{ data.class_name }}</div>
        </v-col>
        <v-col class="divider px-3">
          <div class="font-weight-light">{{ $t("app.type") }}</div>
          <div class="font-weight-bold">
            {{ data.type }}
          </div>
        </v-col> -->
        <v-col class="px-3">
          <div class="font-weight-light">{{ $t("app.school_year") }}</div>
          <div class="font-weight-bold">
            <v-select
              v-model="queryData.school_year"
              :items="schoolYearList"
              :label="$t('journal.school_year')"
              :class="$vuetify.breakpoint.smAndUp && 'select-225'"
              :item-text="
                item =>
                  `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
              "
              :loading="loadingSchoolYear"
              item-value="id"
              outlined
              hide-details
              dense
              class="body-2 ma-2"
              @change="getData()"
            >
              <template #item="{ item }">
                <span v-if="item.id !== 'all'" class="caption">
                  {{ item.start_year }} / {{ item.end_year }}, Semester
                  {{ item.semester }}
                </span>
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-icon
                      v-on="on"
                      :color="schoolYearInfo(item.status).color"
                      small
                      class="ml-1"
                      >{{ schoolYearInfo(item.status).name }}</v-icon
                    >
                  </template>
                  <span>{{ schoolYearInfo(item.status).info }}</span>
                </v-tooltip>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-5 px-4" flat>
      <v-row no-gutters class="py-6">
        <v-btn
          v-if="g_role_type != 'STUDENT'"
          :loading="loadingDownloadExcel"
          :disabled="dataTable.length == 0"
          :class="dataTable.length == 0 ? 'primary' : 'gradient-primary'"
          class="mr-2 caption"
          dark
          depressed
        >
          <download-excel
            :fetch="downloadExcel"
            :fields="fields"
            :name="`${$t('score.download_student')} ${g_user.fullname}.xls`"
          >
            <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
              $t("app.download")
            }}</span>
            <v-icon>mdi-cloud-download</v-icon>
          </download-excel>
        </v-btn>
        <span v-show="total > 0">{{ total }} {{ $t("app.subject") }}</span>
        <v-spacer></v-spacer>

        <v-text-field
          v-if="g_role_type != 'STUDENT'"
          v-model="queryData.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
          style="max-width: 250px"
        />
      </v-row>
      <v-divider class="py-4"></v-divider>
      <v-row class="pb-4">
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="dataTable"
            :loading="loading"
            :options.sync="options"
            class="elevation-0"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.teachers="{ item, value }">
              <v-row v-if="value.length > 1" no-gutters align="start">
                <span v-if="!item.viewMore">
                  <span>{{ value[0].name }}</span>
                </span>
                <span v-else>
                  <div v-for="ht in value" :key="ht.id" class="my-2">
                    {{ ht.name }}
                  </div>
                </span>

                <v-spacer></v-spacer>

                <v-btn
                  small
                  icon
                  @click="item.viewMore = !item.viewMore"
                  :class="item.viewMore ? 'mt-2' : ''"
                >
                  <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </v-row>
              <v-row v-if="value.length == 1" no-gutters>{{
                value[0].name
              }}</v-row>
            </template>
            <template v-slot:item.action="{ item }">
              <v-row justify="space-between">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small @click="toDetail(item)">
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("score.to_detail") }}</span>
                </v-tooltip>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <Pagination
            :length="length"
            :total="total"
            :current-page="queryData.page"
            :limit="queryData.limit"
            :handler="paginateChange"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import Pagination from "@/components/Pagination";

import { get_school_year_list } from "@/api/admin/schoolClass";
import { getExamStudent } from "@/api/admin/academic/score";

export default {
  metaInfo: {
    title: i18n.t("routes.score"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Pagination
  },
  data() {
    return {
      loadingDownloadExcel: false,
      data: {},
      fields: {
        [i18n.t("subjects.subjects")]: "lesson_text",
        [i18n.t("subjects.kkm")]: "kkm",
        [i18n.t("subjects.teachers")]: {
          field: "teachers",
          callback: value => value.map(d => d.name)
        }
      },
      schoolYear: [],
      schoolYearList: [],
      loadingSchoolYear: [],
      total: 0,
      length: 0,
      loading: false,
      dataTable: [],
      options: {},
      tableHeaders: [
        {
          text: i18n.t("score.table.subject"),
          align: "left",
          value: "lesson_text"
        },
        {
          text: i18n.t("score.table.kkm"),
          value: "kkm"
        },
        {
          text: i18n.t("score.table.homeroom"),
          value: "teachers"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 75
        }
      ],
      queryData: {
        page: 1,
        limit: 10,
        school_year: null,
        sort: ["lesson_text"],
        order: "ASC",
        search: ""
      }
    };
  },
  created() {
    this.loadingSchoolYear = true;
    get_school_year_list().then(res => {
      if (res.data.code) {
        this.schoolYearList = res.data.data;
        const schoolYearActive = res.data.data.find(r => r.status == 1);
        if (schoolYearActive) this.queryData.school_year = schoolYearActive.id;
        this.loadingSchoolYear = false;
        this.getData();
      }
    });
  },
  mounted() {
    if (this.$route.query.schoolyear) {
      this.queryData.school_year = Number(this.$route.query.schoolyear);
      this.getData();
    }
  },
  computed: {
    ...mapGetters(["g_darkmode", "g_user", "g_role_type", "g_user_id"])
  },
  methods: {
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await getExamStudent(query);
      this.loadingDownloadExcel = false;
      if (res.data.code) {
        if (res.data.data.data.length == 0) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.data_not_found"),
            color: "error"
          });
        } else return res.data.data.data;
      }
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    getData() {
      this.loading = true;
      getExamStudent(this.queryData)
        .then(res => {
          if (res.data.code) {
            const d = res.data.data;
            const newData = [];
            d.data.map(r => newData.push({ ...r, viewMore: false }));
            this.dataTable = newData;
            this.total = d.total;
            this.length = d.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    toDetail(param) {
      this.$router.push(
        `score/${param.lesson}/${this.queryData.school_year}?user=${this.g_user_id}`
      );
      // console.log(`score/${param.lesson}/${this.queryData.school_year}?user=${this.g_user_id}`)
    }
  }
};
</script>
